import React from "react";

import Layout from "../other/layout";
import SEO from "../other/seo";

import styles from "../components/AC-StaticPages/style/styles.module.css";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.title}>
          <h1>404</h1>
          <p className={styles.toolParag}>
            You just hit a route that doesn't exist...
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
